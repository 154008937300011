
export const columns = [
  {
    title: '任务名称',
    dataIndex: 'name'
  },
  {
    title: '楼盘名称',
    dataIndex: 'house.name'
  },
  {
    title: '任务编号',
    dataIndex: 'id'
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '进度',
    dataIndex: 'salNum',
    scopedSlots: { customRender: 'salNum' },
    sorter: true
  },
  {
    title: '有效期',
    dataIndex: 'validDate',
    ellipsis: true,
    sorter: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export const statusMap = {
  0: {
    status: 'default',
    text: '未审核'
  },
  1: {
    status: 'processing',
    text: '审核通过'
  }
}
