<template>
  <a-modal
    title="任务二维码"
    class="qrcode"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleCancel">
        确定
      </a-button>
    </template>
    <div>
      <img class="myImg" :src="qrcodeImg ? qrcodeImg : errorImg" alt="二维码" slot="cover" />
    </div>
  </a-modal>
</template>

<script>
import errorImg from '@/assets/img/errorImg.png'
export default {
  data() {
    return {
      noticeTitle: '二维码管理',
      generateQcode: '/api/v1/task/generateQcode',

      developerId: null,
      taskId: null,

      visible: false,
      loading: false,

      errorImg,
      qrcodeImg: null
    }
  },
  computed: {
    serial() {
      // let serial = this.$store.state.env.serial
      // if (serial.indexOf('11.0.2.101:8911') !== -1 || serial.indexOf('11.0.1.139:8911')) {
      //   serial = 'http://localhost:8000'
      // }
      console.log(window.location.origin);
      return window.location.origin
    }
  },
  methods: {
    showModal(record = null) {
      console.log(record, 'record')
      this.loading = false
      if (record) {
        this.taskId = record.id
        this.developerId = record.house.developer.id
        this.visible = true
        this.fetchInfo()
      } else {
        this.visible = true
      }
    },
    handleCancel() {
      this.visible = false
    },
    fetchInfo() {
      this.loading = true
      const { taskId, developerId } = this
      const param = {}
      if (taskId && developerId) {
        Object.assign(param, { taskId, developerId })
        this.qrcodeImg = this.requestFormat(this.serial + this.generateQcode, param)
        console.log(this.qrcodeImg, 'this.qrcodeImg')
      } else {
        this.$notification.error({
          message: this.noticeTitle,
          description: '没有开发商信息和任务信息！'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.qrcode {
  /deep/ .ant-modal-body {
    padding: 0;
  }
}
.myImg {
  width: 640px;
}
</style>
