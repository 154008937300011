<template>
  <page-header-wrapper>
    <a-card :bordered="false" class="card_bottom">
      <a-row>
        <a-col :sm="12" :xs="24">
          <info title="总任务" :value="`${allCount}个任务`" :bordered="true" />
        </a-col>
        <a-col :sm="12" :xs="24">
          <info title="进行中任务" :value="`${runningCount}个任务`" :bordered="true" />
        </a-col>
      </a-row>
    </a-card>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div v-if="btnPermission('task_edit')" class="table-operator">
        <a-button v-if="btnPermission('task_edit')" type="primary" icon="plus" @click="handleAdd">发布</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="tasksData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="salNum" slot-scope="text, record">
          <a-progress
            :stroke-color="{
              from: '#108ee9',
              to: '#87d068'
            }"
            :percent="text / record.total"
            status="active"
          />
        </span>

        <span v-if="btnPermission('task_edit')" slot="action" slot-scope="text, record">
          <template>
            <a @click="handleLook(record)">查看</a>
            <a-divider type="vertical"></a-divider>
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a type="primary" icon="barcode" @click="qrCode(record)">二维码</a>
          </template>
        </span>
        <span v-else slot="action">
          暂无权限
        </span>
      </s-table>
    </a-card>
    <code-create ref="codeCreate"></code-create>
  </page-header-wrapper>
</template>

<script>
import Info from './components/Info'
import { STable, Ellipsis } from '@/components'
import { queryTasks, statisticsTask } from '@/api/tasks'
import { columns, statusMap } from './js/index'

import CodeCreate from './components/CodeCreate'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    Info,
    CodeCreate
  },
  data() {
    this.columns = columns
    return {
      noticeTitle: '任务管理',
      runningCount: 0,
      allCount: 0,
      totalCount: 0,
      queryParam: {},
      tasksData: parameter => {
        const param = {
          userId: this.userId,
          status: 1
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return queryTasks(params).then(res => {
          const result = this.resFormat(res)
          result.data.forEach(item => {
            item.validDate = `${item.startTime.slice(0, 11)} 到 ${item.endTime.slice(0, 11)}`
          })
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.getTaskStatictisc()
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    qrCode(record) {
      this.$refs.codeCreate.showModal(record)
    },
    getTaskStatictisc() {
      statisticsTask().then(res => {
        this.allCount = this.priceFormat(res.data.allCount)
        this.runningCount = this.priceFormat(res.data.runningCount)
      })
    },
    handleAdd() {
      this.$router.push({
        path: '/task/release/index'
      })
    },
    handleEdit(record) {
      const id = record.id
      this.$router.push({
        path: '/task/release/index',
        query: {
          id
        }
      })
    },
    handleLook(record) {
      const id = record.id
      this.$router.push({
        path: '/task/haveRelease/detail',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
